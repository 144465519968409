<template>
  <reminder-detail
    :reminder-type="'dunning-reminders'"
    :tab="tab"
    v-on="$listeners"
  />
</template>

<script>

export default {
  components: {
    ReminderDetail: () => import('./reminder-detail')
  },
  async created () {
    Object.assign(this.tab, { cancel: this.cancel })
  },
  methods: {
    cancel () {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    }
  },
  props: {
    tab: Object
  }
}
</script>

<style lang="stylus" scoped></style>
